<template>
  <div class="main-page">
    <header class="header" :class="{ colored: menuShow }">
      <div class="header-inner">
        <div class="container">
          <nav class="header-nav">
            <div class="header-left">
              <div class="header-left-inner">
                <img :src="HOME_DATA?.header?.logo" alt="Logo" class="logo" />
                <div id="menu" class="header-menu" :class="{ show: menuShow }">
                  <div class="header-menu-inner">
                    <ul v-if="HOME_DATA?.navigations" class="header-links-list">
                      <template v-for="(item, index) in HOME_DATA?.navigations" :key="index">
                        <li class="header-links-item">
                          <a v-if="item.is_main" v-smooth-scroll="{ duration: 500, offset: -90, updateHistory: false }" href="#menu-list" class="header-link" @click="anchorClick">
                            {{ item.label }}
                            <span v-if="item.label_color">{{ item.label_color }}</span>
                          </a>
                          <a v-else v-smooth-scroll="{ duration: 500, offset: -90, updateHistory: false }" :href="anchorLink(item)" class="header-link" @click="anchorClick">
                            {{ item.label }}
                            <span v-if="item.label_color">{{ item.label_color }}</span>
                          </a>
                        </li>
                      </template>
                    </ul>
                    <div class="header-menu-bottom">
                      <Button @click="toLogin" mode="white">{{ HOME_DATA?.menu?.btn_login }}</Button>
                      <Button @click="toSignUp">{{ HOME_DATA?.menu?.btn_connect }}</Button>
                      <div v-if="HOME_DATA?.footer?.social" class="header-menu-bottom-contact">
                        <div>
                          <a v-if="HOME_DATA?.footer?.social?.facebook" :href="HOME_DATA?.footer?.social?.facebook" rel="nofollow" class="header-menu-contact"><img src="../assets/img/facebook.svg" width="16" height="16" alt="facebook"/></a>
                          <a v-if="HOME_DATA?.footer?.social?.instagram" :href="HOME_DATA?.footer?.social?.instagram" rel="nofollow" class="header-menu-contact"><img src="../assets/img/instagram.svg" width="16" height="16" alt="instagram"/></a>
                        </div>
                        <a :href="phoneLink" class="header-menu-contact">{{ HOME_DATA?.footer?.phone }}</a>
                        <a :href="emailLink" class="header-menu-contact">{{ HOME_DATA?.footer?.email }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dropdown" @mouseleave="dropdownShow = false" :class="{ closed: !dropdownShow }">
                <span class="icon" @click="dropdownShow = !dropdownShow">
                  {{ showLang }}
                  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L4.5 5L8 1" stroke="var(--blue-main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </span>
                <transition name="fade">
                  <ul v-show="dropdownShow" class="dropdown-menu">
                    <li v-for="(item, index) in this.languagesArr" :key="index">
                      <span class="dropdown-link" @click="langToggle(item.code)">{{ item.name }}</span>
                    </li>
                  </ul>
                </transition>
              </div>
            </div>
            <div class="header-right">
              <Button @click="toLogin" mode="white">{{ HOME_DATA?.menu?.btn_login }}</Button>
              <Button @click="toSignUp">{{ HOME_DATA?.menu?.btn_connect }}</Button>
            </div>
            <button id="menu-toggler" class="burger" @click="menuToggle">
              <svg v-if="!menuShow" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="4" fill="white"></rect>
                <line x1="7" y1="9.3" x2="25" y2="9.3" stroke="var(--dark-100)" stroke-width="1.4"></line>
                <line x1="7" y1="15.3" x2="25" y2="15.3" stroke="var(--dark-100)" stroke-width="1.4"></line>
                <line x1="7" y1="21.3" x2="25" y2="21.3" stroke="var(--dark-100)" stroke-width="1.4"></line>
              </svg>
              <svg v-if="menuShow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 18L18 6M6 6L18 18" stroke="var(--dark-100)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </header>
    <div class="main-block">
      <img class="main-block-img" src="https://api.blunch.com.ua/storage/app/media/main_page/main.png" alt="main-block" />
      <div class="main-block-inner">
        <div class="container" :id="HOME_DATA?.company?.link?.url">
          <img v-if="HOME_DATA?.company?.text_image" class="boutic-img" :src="HOME_DATA?.company?.text_image" alt="b_launch" />
          <h1 class="main-text">
            <template v-for="(item, index) in HOME_DATA?.company?.text" :key="index">
              <span v-if="item.color != ''">{{ item.text }}</span>
              <template v-else>{{ item.text }}</template>
            </template>
          </h1>
          <Button @click="toSignUp">{{ HOME_DATA?.company?.btn }}</Button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="about-us-block" :id="HOME_DATA?.about?.link?.url">
        <p class="about-us-title">{{ HOME_DATA?.about?.title }}</p>
        <div class="huge-text">
          <template v-for="(item, index) in HOME_DATA?.about?.text" :key="index">
            <span v-if="item.color != ''">{{ item.text }}</span>
            <p v-else>{{ item.text }}</p>
          </template>
        </div>
      </div>
      <div class="advantages-wrapper">
        <div class="advantage-block" v-for="(block, index) in HOME_DATA?.about?.blocks" :key="index">
          <div class="advantage-text">
            <p class="huge-text">
              <template v-for="(item, index) in block.text" :key="index">
                <span v-if="item.color != ''">{{ item.text }}</span>
                <template v-else>{{ item.text }}</template>
              </template>
            </p>
          </div>
          <div v-if="block?.is_order" class="advantage-img-wrapper day-animation-wrapper" id="day-animation">
            <div class="days-animation-block">
              <template v-for="(dish, index) in block.data" :key="index">
                <DaysAnimation v-if="index < 2" :dish="dish" :index="index"></DaysAnimation>
              </template>
            </div>
            <div class="days-animation-block">
              <template v-for="(dish, index) in block.data" :key="index">
                <DaysAnimation v-if="index > 1 && index < 5" :dish="dish"></DaysAnimation>
              </template>
            </div>
          </div>
          <div v-else-if="block?.is_card" class="advantage-img-wrapper" id="dish-animation">
            <DishAnimation :dishes="block?.data"></DishAnimation>
          </div>
          <div v-else class="advantage-img-wrapper animated-img">
            <img class="advantage-img" :src="block?.image" :alt="'about-us-' + (index + 1)" />
          </div>
        </div>
      </div>
      <div class="steps-wrapper" :id="HOME_DATA?.workSteps?.link?.url">
        <p class="steps-title">{{ HOME_DATA?.workSteps?.title }}</p>
        <div class="steps-inner">
          <div v-if="HOME_DATA?.workSteps?.steps?.one" class="step-block">
            <div class="step-top">
              <div class="step-row" v-for="(item, index) in HOME_DATA?.workSteps?.steps?.one?.blocks" :key="index">
                <span class="step-name"
                  >{{ item?.name }} <span v-if="item?.name_text" class="gray">{{ item?.name_text }}</span></span
                >
                <span class="step-sum"
                  ><span class="blue">{{ item?.color }}</span> {{ item?.text }}</span
                >
              </div>
            </div>
            <div class="step-bottom">
              <p class="step-label">{{ HOME_DATA?.workSteps?.steps?.one?.name }}</p>
              <p class="step-text-main">{{ HOME_DATA?.workSteps?.steps?.one?.description }}</p>
            </div>
          </div>
          <div v-if="HOME_DATA?.workSteps?.steps?.two" class="step-block">
            <div class="step-top">
              <div class="step-row">
                <div class="day-square">{{ HOME_DATA?.workSteps?.steps?.two?.days?.mon }}</div>
                <div class="day-square">{{ HOME_DATA?.workSteps?.steps?.two?.days?.tue }}</div>
                <div class="day-square active">
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.30469 6.27083L6.01302 8.97917L11.4297 3.5625" stroke="white" stroke-width="1.25157" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{ HOME_DATA?.workSteps?.steps?.two?.days?.wed }}
                </div>
                <div class="day-square">{{ HOME_DATA?.workSteps?.steps?.two?.days?.thu }}</div>
                <div class="day-square">{{ HOME_DATA?.workSteps?.steps?.two?.days?.fri }}</div>
              </div>
              <div v-if="HOME_DATA?.workSteps?.steps?.two?.dish" class="day-dish">
                <div class="day-dish-header">
                  <span class="day-name">{{ HOME_DATA?.workSteps?.steps?.two?.dish?.day }}</span>
                  <span>{{ HOME_DATA?.workSteps?.steps?.two?.dish?.price }}</span>
                </div>
                <div class="day-dish-cell">
                  <img :src="HOME_DATA?.workSteps?.steps?.two?.dish?.image" alt="dish-min" />
                  <div class="day-dish-content">
                    <span class="day-dish-text">{{ HOME_DATA?.workSteps?.steps?.two?.dish?.name }}</span>
                    <span class="day-dish-price">
                      <span class="gray">{{ HOME_DATA?.workSteps?.steps?.two?.dish?.qty }}</span>
                      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.30078 12.5212L12.0754 4.74658M4.30078 4.74658L12.0754 12.5212" stroke="#000E29" stroke-opacity="0.4" stroke-width="1.45775" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="step-bottom">
              <p class="step-label">{{ HOME_DATA?.workSteps?.steps?.two?.name }}</p>
              <p class="step-text-main">{{ HOME_DATA?.workSteps?.steps?.two?.description }}</p>
            </div>
          </div>
          <div v-if="HOME_DATA?.workSteps?.steps?.three" class="step-block">
            <div class="step-top">
              <div v-for="(item, index) in HOME_DATA?.workSteps?.steps?.three?.blocks" :key="index" class="step-row">
                <span class="step-name"
                  >{{ item?.date }} <span class="gray">{{ item?.date_text }}</span></span
                >
                <span class="step-sum"
                  >{{ item?.text }} <span class="blue">{{ item?.color }}</span></span
                >
              </div>
            </div>
            <div class="step-bottom">
              <p class="step-label">{{ HOME_DATA?.workSteps?.steps?.three?.name }}</p>
              <p class="step-text-main">{{ HOME_DATA?.workSteps?.steps?.three?.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="MAIN_DISH_LIST && MAIN_DISH_LIST.length > 0">
      <div class="container">
        <p id="menu-list" class="steps-title">{{ HOME_DATA?.other?.dishes }}</p>
      </div>
      <div class="swiper swiper-container dishSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" :style="`${slideWidth}px !important`"></div>
          <div class="swiper-slide" v-for="dish in MAIN_DISH_LIST" :key="dish.id">
            <DishCard :dish="dish" :hideBtns="true" customClass="slide-card"></DishCard>
          </div>
        </div>
      </div>
    </template>
    <div class="container">
      <div class="faq-wrapper" :id="HOME_DATA?.faq?.link?.url">
        <p class="faq-title">{{ HOME_DATA?.faq?.title }}</p>
        <div class="accordion-block">
          <Accordion :faq="HOME_DATA?.faq?.data"></Accordion>
        </div>
      </div>
    </div>
    <footer>
      <div class="container">
        <div class="row f-top f-align">
          <div class="col-xs-100 col-sm-100 col-md-50 col-lg-50">
            <a :href="phoneLink" class="big-link">{{ HOME_DATA?.footer?.phone }}</a>
          </div>
          <div class="col-xs-100 col-sm-100 col-md-50 col-lg-50">
            <div class="f-right f-socials-mob">
              <a :href="emailLink" class="big-link">{{ HOME_DATA?.footer?.email }}</a>
              <div v-if="HOME_DATA?.footer?.social" class="f-social-link_list_mob">
                <a v-if="HOME_DATA?.footer?.social?.facebook" :href="HOME_DATA?.footer?.social?.facebook" rel="nofollow">
                  <img src="../assets/img/facebook.svg" width="16" height="16" alt="facebook" />
                </a>
                <a v-if="HOME_DATA?.footer?.social?.instagram" :href="HOME_DATA?.footer?.social?.instagram" rel="nofollow">
                  <img src="../assets/img/instagram.svg" width="16" height="16" alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row f-align">
          <div class="col-xs-100 col-sm-80 col-md-80">
            <p class="copyright">
              <template v-for="(item, index) in OTHER_TRANSLATE?.footer?.text" :key="index">
                <a v-if="item?.link != ''" :href="item?.link" target="_blank" :class="item?.color">{{ item?.text }}</a>
                <span v-else :class="item?.color">{{ item?.text }}</span>
              </template>
            </p>
          </div>
          <div class="col-xs-100 col-sm-20 col-md-20">
            <div class="f-right">
              <div class="row f-align">
                <div class="col-xs-hidden col-sm-50 col-md-50 col-lg-50">
                  <ul v-if="HOME_DATA?.footer?.social" class="f-social-link_list">
                    <li v-if="HOME_DATA?.footer?.social?.facebook" class="f-social-link_item">
                      <a :href="HOME_DATA?.footer?.social?.facebook" rel="nofollow" class="f-social-link">
                        <img class="icon" src="../assets/img/facebook.svg" width="16" height="16" alt="facebook" />
                        <span class="gray">Facebook</span>
                      </a>
                    </li>
                    <li v-if="HOME_DATA?.footer?.social?.instagram" class="f-social-link_item">
                      <a :href="HOME_DATA?.footer?.social?.instagram" rel="nofollow" class="f-social-link">
                        <img class="icon" src="../assets/img/instagram.svg" width="16" height="16" alt="instagram" />
                        <span class="gray">Instagram</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-xs-100 col-sm-50 col-md-50 col-lg-50"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row f-bottom">
          <div class="policy">
            <a target="_blank" class="policy-link" :href="HOME_DATA?.footer?.policy?.link">{{ HOME_DATA?.footer?.policy?.text }}</a>

            <svg class="policy-card" width="100%" height="100%" viewBox="0 0 417 136" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path id="path3789" d="M271.39,0c-29.585,0 -56.023,15.334 -56.023,43.666c-0,32.491 46.889,34.735 46.889,51.058c0,6.872 -7.876,13.025 -21.328,13.025c-19.091,-0 -33.36,-8.597 -33.36,-8.597l-6.105,28.59c-0,-0 16.437,7.261 38.26,7.261c32.346,0 57.798,-16.087 57.798,-44.903c0,-34.333 -47.085,-36.51 -47.085,-51.66c0,-5.384 6.466,-11.283 19.88,-11.283c15.134,-0 27.482,6.252 27.482,6.252l5.975,-27.613c0,0 -13.435,-5.796 -32.383,-5.796Zm-270.674,2.084l-0.716,4.168c-0,-0 12.446,2.278 23.656,6.822c14.434,5.21 15.463,8.244 17.893,17.665l26.49,102.115l35.509,0l54.705,-130.77l-35.428,-0l-35.151,88.911l-14.344,-75.365c-1.315,-8.626 -7.978,-13.546 -16.134,-13.546l-56.48,-0Zm171.783,-0l-27.792,130.77l33.783,0l27.694,-130.77l-33.685,-0Zm188.421,-0c-8.146,-0 -12.463,4.361 -15.63,11.983l-49.494,118.787l35.427,0l6.855,-19.798l43.161,0l4.168,19.798l31.26,0l-27.271,-130.77l-28.476,-0Zm4.608,35.33l10.501,49.071l-28.134,0l17.633,-49.071Z" style="fill:#1434cb;fill-rule:nonzero;"/></svg>

            <svg class="policy-card" width="52px" height="32px" viewBox="0 0 52 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
              <title>MC-logo-52</title>
              <desc>Created with Sketch.</desc>
              <g id="Components---Sprint-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="assets-/-logo-/-mastercard-/-symbol">
                  <polygon id="Fill-1" fill="#FF5F00" points="18.7752605 28.537934 32.6926792 28.537934 32.6926792 3.41596003 18.7752605 3.41596003"></polygon>
                  <path d="M19.6590387,15.976947 C19.6590387,10.8803009 22.03472,6.34107274 25.7341024,3.41596003 C23.0283795,1.27638054 19.6148564,0 15.9044284,0 C7.12054904,0 0.000132546844,7.15323422 0.000132546844,15.976947 C0.000132546844,24.8006598 7.12054904,31.953894 15.9044284,31.953894 C19.6148564,31.953894 23.0283795,30.6775135 25.7341024,28.537934 C22.03472,25.6123775 19.6590387,21.0735931 19.6590387,15.976947" id="Fill-2" fill="#EB001B"></path>
                  <path d="M50.9714634,25.8771954 L50.9714634,25.257201 L50.8101981,25.257201 L50.6250744,25.6836968 L50.4395088,25.257201 L50.2782434,25.257201 L50.2782434,25.8771954 L50.3917919,25.8771954 L50.3917919,25.4094258 L50.5658701,25.8128438 L50.6838368,25.8128438 L50.857915,25.4085382 L50.857915,25.8771954 L50.9714634,25.8771954 Z M49.9504109,25.8771954 L49.9504109,25.3628264 L50.157184,25.3628264 L50.157184,25.2580887 L49.6314148,25.2580887 L49.6314148,25.3628264 L49.8377461,25.3628264 L49.8377461,25.8771954 L49.9504109,25.8771954 Z M51.4680723,15.9768139 C51.4680723,24.8005266 44.347214,31.9537609 35.5637764,31.9537609 C31.8533484,31.9537609 28.4393835,30.6773803 25.7341024,28.5378008 C29.4334848,25.6126881 31.8091661,21.07346 31.8091661,15.9768139 C31.8091661,10.8806116 29.4334848,6.34138341 25.7341024,3.41582689 C28.4393835,1.2762474 31.8533484,-0.000133141225 35.5637764,-0.000133141225 C44.347214,-0.000133141225 51.4680723,7.15310107 51.4680723,15.9768139 L51.4680723,15.9768139 Z" id="Fill-4" fill="#F79E1B"></path>
                </g>
              </g>
            </svg>

            <img src="../assets/img/tas.png" alt="tas" class="policy-card">
          </div>
          <div class="dev_info">
            <span class="dev_text">
              <span class="gray">{{ HOME_DATA?.footer?.code?.name }}</span> <a class="gray" :href="HOME_DATA?.footer?.code?.link">Intertech</a>
            </span>
            <span class="dev_text">
              <span class="gray">{{ HOME_DATA?.footer?.design?.name }}</span> <a class="gray" :href="HOME_DATA?.footer?.design?.link">Other land</a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Swiper from "swiper";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
SwiperCore.use([Navigation, Pagination]);
import Button from "@/components/parts/button.vue";
import Accordion from "@/components/collapse/accordion.vue";
import DaysAnimation from "@/components/parts/days-animation";
import DishAnimation from "@/components/parts/dish-animation";
import DishCard from "@/components/dish-card";
import { mapActions, mapGetters } from "vuex";
import "swiper/swiper-bundle.css";
export default {
  components: {
    Button,
    Accordion,
    DishAnimation,
    DaysAnimation,
    DishCard
  },
  data() {
    return {
      menuShow: false,
      dropdownShow: false,
      swiper: null,
      activeLang: {
        name: "",
        code: ""
      },
      languagesArr: []
    };
  },
  methods: {
    ...mapActions(["updateHomeData", "OtherTranslate", "SetMainDishList"]),
    chosenLang() {
      if (!localStorage.getItem("lang")) localStorage.setItem("lang", "uk");
      this.activeLang = this.HOME_DATA?.languages.find(lang => lang.code == localStorage.getItem("lang"));
      this.languagesArr = Object.assign([], this.HOME_DATA?.languages);
      this.languagesArr = this.languagesArr.filter(lang => lang.code !== this.activeLang.code);
      return this.activeLang.name;
    },
    anchorLink(item) {
      let anchor = "#" + item.url;
      return anchor;
    },
    langToggle(item) {
      localStorage.setItem("lang", item);
      this.updateHomeData();
      this.OtherTranslate();
      this.SetMainDishList();
      this.dropdownShow = false;
    },
    menuToggle() {
      const body = document.querySelector("body");
      body.classList.toggle("overflowed");
      this.menuShow = !this.menuShow;
    },
    anchorClick() {
      const body = document.querySelector("body");
      body.classList.remove("overflowed");
      this.menuShow = false;
    },
    toLogin() {
      this.$router.push({ name: "Login" });
      const body = document.querySelector("body");
      body.classList.remove("overflowed");
    },
    toSignUp() {
      this.$router.push({ name: "SignUp" });
      const body = document.querySelector("body");
      body.classList.remove("overflowed");
    },
    animation() {
      const blocks = document.querySelectorAll(".animated-img");

      const options = {
        root: null,
        rootMargin: "0px",
        treshold: 0.1
      };

      function handleBlock(myBlock) {
        myBlock.forEach(myBlockSingle => {
          if (myBlockSingle.intersectionRatio > 0) opacityBlock(myBlockSingle.target);
        });
      }

      function opacityBlock(block) {
        block.classList.add("animated");
      }

      const observerBlock = new IntersectionObserver(handleBlock, options);

      blocks.forEach(block => {
        observerBlock.observe(block);
      });
    },
    animationSteps() {
      const blocks = document.querySelectorAll(".steps-inner");

      const options = {
        root: null,
        rootMargin: "0px",
        treshold: 0.1
      };

      function handleBlock(myBlock) {
        myBlock.forEach(myBlockSingle => {
          if (myBlockSingle.intersectionRatio > 0) opacityBlock(myBlockSingle.target);
        });
      }

      function opacityBlock(block) {
        block.classList.add("animated");
      }

      const observerBlock = new IntersectionObserver(handleBlock, options);

      blocks.forEach(block => {
        observerBlock.observe(block);
      });
    },
    dayAnimation() {
      const block = document.getElementById("day-animation");

      const options = {
        root: null,
        rootMargin: "0px",
        treshold: 0.1
      };

      function handleBlock(myBlock) {
        myBlock.forEach(myBlockSingle => {
          if (myBlockSingle.intersectionRatio > 0) animationEffect(myBlockSingle.target);
        });
      }

      function animationEffect() {
        let daysArr = document.querySelectorAll("#day-animation .collapsed");

        let time = 0;

        daysArr.forEach(function(item) {
          setTimeout(() => {
            let height = item.querySelector(".day-bottom-inner").offsetHeight;
            item.querySelector(".day-bottom").style.height = height + "px";

            let imgArr = item.querySelectorAll(".dish-cell");

            let time2 = 300;

            imgArr.forEach(function(item) {
              setTimeout(() => {
                item.classList.add("animated");
              }, time2);

              time2 += 300;
            });
          }, time);

          time += 500;
        });
      }

      const observerBlock = new IntersectionObserver(handleBlock, options);

      observerBlock.observe(block);
    },
    Anim() {
      this.animation();
      this.animationSteps();
      this.dayAnimation();
    }
  },
  computed: {
    ...mapGetters(["HOME_DATA", "OTHER_TRANSLATE", "MAIN_DISH_LIST"]),
    year() {
      return new Date().getFullYear();
    },
    phoneLink() {
      let tel = "";
      if (this.HOME_DATA) tel = "tel:" + this.HOME_DATA.footer.phone;
      return tel.replace(/\s/g, "");
    },
    emailLink() {
      let email = "";
      if (this.HOME_DATA) email = "mailto:" + this.HOME_DATA.footer.email;
      return email;
    },
    showLang() {
      let lang = "";
      if (this.HOME_DATA) lang = this.chosenLang();
      return lang;
    }
  },
  async mounted() {
    await this.updateHomeData();
    await this.OtherTranslate();
    await this.SetMainDishList();
    this.swiper = new Swiper(".dishSwiper", {
      slidesPerView: 1,
      spaceBetween: 16,
      loop: false,
      breakpoints: {
        634: {
          slidesPerView: 2
        },
        980: {
          slidesPerView: 3
        },
        1090: {
          slidesPerView: 4
        },
        // 1300: {
        //   slidesPerView: 5
        // },
        1600: {
          slidesPerView: 6
        }
      }
    });
    window.addEventListener("scroll", this.Anim);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.Anim);
  }
};
</script>

<style lang="scss">
.dishSwiper {
  position: relative;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   width: 6%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   z-index: 2;
  //   background: linear-gradient(to left,rgba(245, 248, 250,0),#f5f8fa 100%);
  // }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 6%;
  //   height: 100%;
  //   top: 0;
  //   right: 0;
  //   z-index: 2;
  //   background: linear-gradient(to left,#f5f8fa,rgba(245, 248, 250,0) 100%);
  // }
  @media (max-width: 1199px) {
    padding: 0 20px;
  }
  @media (min-width: 992px) {
    margin-bottom: 140px;
  }
  @media (max-width: 991px) {
    margin-bottom: 55px;
  }
  .swiper-slide {
    padding-top: 5px;
    display: flex;
    justify-content: center;
    &:nth-child(1) {
      width: calc((100% - 1440px) / 2) !important;
      @media (max-width: 1199px) {
        display: none;
      }
    }
  }
  // .dish-card {
  //   width: 100%;
  // }
  .swiper-button-prev {
    position: absolute;
    left: 0;
    top: 62%;
    transform: translateY(-50%);
    svg {
      transform: rotate(180deg);
    }
  }
  .swiper-button-next {
    position: absolute;
    right: 0;
    top: 62%;
    transform: translateY(-50%);
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: #3cafe9;
    border-radius: 10px;
    width: 48px;
    height: 48px;
    opacity: 0.9;
    stroke-width: 3;
    stroke-linecap: round;

    &:after {
      display: none;
    }

    svg {
      width: 1.2em;
      height: 1.2em;

      path {
        stroke: #fff;
      }
    }

    &:hover {
      background: var(--blue-btn-hover);

      svg path {
        stroke: #fff;
      }
    }
  }
}
.main-page {
  position: relative;
  min-height: 100%;
  background: var(--bg-main);

  .dish-description {
    margin-bottom: 0;
  }

  .micro, .micro-price {
    display: none;
  }

  .dish-card {
    height: 360px;
    min-height: 360px;
  }

  .blue-text {
    color: var(--blue-main);
  }

  ol,
  ul {
    list-style: none;
  }

  button .m-text-des span {
    font-family: "Aeonik Pro Regular", sans-serif;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.9px;
  }

  .container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
  }

  .logo {
    margin-right: 23px;
    max-height: 24px;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.5s ease;
    z-index: 5;

    @media (min-width: 992px) {
      margin-bottom: 104px;
      background: rgba(0, 0, 0, 0.04);
    }

    @media (max-width: 991px) {
      margin-bottom: 47px;

      &.colored {
        background: var(--bg-white);

        .header-inner:after {
          backdrop-filter: none;
        }
      }
    }

    &-inner {
      position: relative;

      @media (min-width: 992px) {
        padding: 12px 0;
      }

      @media (max-width: 991px) {
        padding: 20px 0 16px;
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 17px);
      }

      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        &:after {
          mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.95) 81%, rgba(255, 255, 255, 0) 100%);
          backdrop-filter: blur(13px);
        }
      }

      @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.8) 80%, rgba(255, 255, 255, 0) 100%);
        }

        &:after {
          top: auto;
          bottom: 0;
          height: 35px;
          background: rgba(255, 255, 255, 0.95);
          filter: blur(10px);
        }
      }

      .container {
        position: relative;
        z-index: 1;
      }
    }

    &-left {
      display: flex;
      width: 100%;

      @media (min-width: 992px) {
        margin-right: 20px;
        justify-content: space-between;
        align-items: center;
      }

      &-inner {
        display: flex;

        @media (min-width: 992px) {
          align-items: center;
        }

        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }

    &-right {
      @media (min-width: 992px) {
        display: flex;
        flex-shrink: 0;
      }

      @media (max-width: 991px) {
        display: none;
      }

      button span {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
      }

      button:not(:last-child) {
        margin-right: 21px;
        box-shadow: var(--box-shadow-second);
      }
    }

    &-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 991px) {
        & > .header-link {
          display: none;
        }
      }
    }

    &-links {
      &-list {
        display: flex;
        align-items: center;
        transition: opacity 0.25s ease;

        @media (min-width: 992px) {
          margin-right: 40px;
        }

        @media (max-width: 991px) {
          height: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }
      }

      &-item {
        @media (min-width: 992px) {
          &:not(:last-child) {
            margin-right: 15px;
          }
        }

        @media (min-width: 1200px) {
          &:not(:last-child) {
            margin-right: 20px;
          }
        }

        @media (max-width: 991px) {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }

    &-link {
      color: var(--dark-100);
      text-decoration: none;
      transition: 0.25s ease;

      &:hover {
        color: var(--blue-main);
      }

      span {
        color: var(--blue-main);
      }

      @media (min-width: 992px) {
        font-size: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      @media (max-width: 991px) {
        font-size: 18px;
        line-height: 32px;
        font-family: "Aeonik Pro Regular", sans-serif;
        text-transform: uppercase;

        span {
          font-family: "Aeonik Pro Regular", sans-serif;
        }
      }
    }

    &-menu {
      @media (max-width: 991px) {
        position: fixed;
        top: 67px;
        left: 0;
        width: 100%;
        height: calc(100vh - 67px);
        background-color: var(--bg-white);
        overflow: hidden;
        z-index: 1;
        transform-origin: 100% 0;
        transition: 0.3s;

        &:not(.show) {
          height: 0;
          transform: scale(0.2);
          visibility: hidden;
          opacity: 0;

          .header-links-list {
            opacity: 0;
          }
        }

        &-contact {
          display: inline-block;
          color: rgba(0, 14, 41, 0.4);
          font-size: 12px;
          letter-spacing: 0.6px;
          text-decoration: none;
          text-transform: uppercase;

          &:not(:last-child) {
            margin-right: 4px;
          }

          img {
            position: relative;
            bottom: 2px;
          }
        }
      }

      &-inner {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 991px) {
          padding-top: 108px;
          flex-direction: column;
          justify-content: space-between;
          overflow-y: auto;
        }
      }

      &-bottom {
        @media (min-width: 992px) {
          display: none;
        }

        @media (max-width: 991px) {
          margin-top: auto;
          padding: 20px;
          bottom: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;

          button {
            margin-left: auto;
            margin-right: auto;
            width: 100%;

            span {
              font-family: "Aeonik Pro Regular", sans-serif;
              font-size: 12px;
            }
          }

          & > button:first-child {
            margin-bottom: 12px;
            background: #f5f8fa;
            border-radius: 8px;
            span {
              color: #3cafe9;
            }
          }

          &-contact {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    .logo {
      position: relative;
      z-index: 2;
    }
  }

  @media (max-width: 991px) {
    .boutic-img {
      position: relative;
      top: 4px;
    }
  }

  .burger {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    background: var(--bg-white);
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 4px;
    z-index: 2;

    @media (min-width: 992px) {
      display: none;
    }

    @media (max-width: 991px) {
      left: 4px;
    }
  }

  .dropdown {
    height: 12px;
    cursor: pointer;

    @media (min-width: 992px) {
      position: relative;
    }

    @media (max-width: 991px) {
      visibility: hidden;
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.4s ease;
      opacity: 0;
      z-index: 1;
    }

    &-menu {
      padding-top: 6px;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }

    &-link {
      color: var(--dark-100);
      font-size: 12px;
      letter-spacing: 0.5px;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: var(--blue-main);
      }
    }

    .icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--dark-100);
      font-size: 12px;
      letter-spacing: 0.5px;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.25s ease;

      &:hover {
        color: var(--blue-main);
      }

      svg {
        margin-left: 3px;
        position: relative;
        bottom: 1px;
        transition: 0.7s ease;
      }
    }

    &:not(.closed) .icon svg {
      transform: rotate(-180deg);
    }
  }

  .main-block {
    position: relative;

    @media (min-width: 992px) {
      height: 740px;
    }

    @media (max-width: 991px) {
      padding-top: 213px;
      height: 550px;
      overflow: hidden;
    }

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right bottom;
      pointer-events: none;

      @media (max-width: 991px) {
        right: -2%;
        top: 2px;
        transform: scale(1.1) rotate(-11deg);
      }

      @media (max-width: 991px) and (min-width: 768px) {
        height: 90%;
      }

      @media (max-width: 767px) {
        height: 61%;
      }
    }

    &-inner {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      z-index: 1;

      @media (min-width: 992px) {
        padding-top: 29px;
      }
    }

    button {
      position: relative;
      bottom: 8px;

      span {
        font-size: 12px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
    }
  }

  .main-text {
    margin-bottom: 24px;
    max-width: 710px;
    font-family: "Aeonik Pro Regular", sans-serif;
    font-weight: 400;
    letter-spacing: 0.6px;

    @media (min-width: 768px) {
      font-size: 62px;
      line-height: 70px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 40px;
      letter-spacing: 0.3px;
    }

    span {
      font-family: "Aeonik Pro Regular", sans-serif;
      font-weight: 400;
      color: var(--blue-main);
      letter-spacing: 0.6px;
    }
  }

  .huge-text {
    font-family: "Aeonik Pro Regular", sans-serif;
    letter-spacing: 0.35px;
    line-height: 48px;

    p {
      font-family: "Aeonik Pro Regular", sans-serif;
    }

    @media (min-width: 1200px) {
      font-size: 36px;
    }

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 30px;
    }

    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.2px;
    }

    span {
      color: var(--blue-main);
      font-family: "Aeonik Pro Regular", sans-serif;
    }
  }

  .about-us {
    &-block {
      padding: 20px;
      background: var(--bg-white);
      border: 1px solid rgba(255, 255, 255, 0.03);
      border-radius: 10px;

      @media (min-width: 992px) {
        margin-bottom: 140px;
      }

      @media (max-width: 991px) {
        margin: 0 -20px 47px;
      }
    }

    &-title {
      color: var(--dark-40);
      font-family: "Aeonik Pro Regular", sans-serif;
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.5px;
      text-transform: uppercase;

      @media (min-width: 992px) {
        margin-bottom: 92px;
      }

      @media (max-width: 991px) {
        margin-bottom: 42px;
      }
    }
  }

  .advantage-block {
    display: flex;
    align-items: center;

    @media (min-width: 1440px) {
      &:nth-child(odd) {
        padding: 0 120px;
      }

      &:nth-child(even) {
        padding: 0 72px 0 120px;
      }
    }

    @media (min-width: 1200px) {
      justify-content: space-between;

      .advantage-img-wrapper {
        align-items: center;
      }
    }

    @media (min-width: 1200px) {
      margin-bottom: 140px;

      &:nth-child(odd) {
        .advantage-img-wrapper {
          justify-content: flex-end;
          align-items: flex-start;
        }
      }

      &:nth-child(even) {
        .advantage-img-wrapper {
          justify-content: flex-start;
          align-items: center;
        }
      }

      &:nth-child(even) {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    @media (max-width: 1199px) and (min-width: 992px) {
      margin-bottom: 60px;
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 991px) {
      margin-bottom: 61px;
      flex-direction: column;
      align-items: center;
    }

    .huge-text {
      @media (max-width: 1199px) and (min-width: 992px) {
        max-width: 700px;
      }

      @media (max-width: 991px) {
        max-width: 270px;
      }
    }
  }

  .days-animation-block:first-child {
    margin-top: -12px;
    align-self: center;
  }

  .days-animation-block:last-child {
    margin-left: 20px;
  }

  .advantage-text {
    flex-shrink: 0;

    @media (min-width: 992px) {
      max-width: 444px;
      padding-right: 10px;
    }

    @media (max-width: 991px) {
      margin-bottom: 20px;
      max-width: 700px;
      align-self: flex-start;
    }
  }

  .advantage-img {
    width: 100%;

    @media (min-width: 1401px) {
      max-width: 640px;
    }

    @media (max-width: 1400px) and (min-width: 992px) {
      max-width: 500px;
    }

    @media (max-width: 991px) {
      max-width: 345px;
    }

    &-wrapper {
      width: 100%;
      display: flex;

      @media (max-width: 1199px) {
        margin-top: 55px;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 991px) {
        margin-top: -4px;
      }

      &.animated-img {
        position: relative;
        top: 100px;
        transition: top 700ms ease, opacity 1700ms ease;
        opacity: 0;

        &.animated {
          top: 0;
          opacity: 1;
        }
      }
    }
  }

  .faq-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .faq-title {
    margin-bottom: 24px;
    font-family: "Aeonik Pro Regular", sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.4px;

    @media (max-width: 991px) {
      margin-bottom: 18px;
      font-size: 22px;
      letter-spacing: 0.2px;
    }
  }

  .accordion-block {
    position: relative;
    bottom: 4px;
    max-width: 927px;
    width: 100%;
  }

  footer {
    padding-bottom: 20px;

    @media (min-width: 992px) {
      margin-top: 136px;
    }

    @media (max-width: 991px) {
      margin-top: 46px;
    }

    .copyright {
      a,
      span {
        border-bottom: 1px solid transparent;
        transition: border-color 250ms ease;
      }
    }

    .blue {
      color: #3cafe9 !important;
      &:hover {
        border-color: #3cafe9 !important;
      }
    }
    .green {
      color: #4aae31 !important;
      &:hover {
        border-color: #4aae31 !important;
      }
    }
    .brown {
      color: #832431 !important;
      &:hover {
        border-color: #832431 !important;
      }
    }
    .violet {
      color: #2e7d1a !important;
      &:hover {
        border-color: #2e7d1a !important;
      }
    }
  }

  .big-link {
    display: inline-block;
    color: #000e29;
    text-decoration: none;
    font-family: "Aeonik Pro Regular", sans-serif;
    border-bottom: 2px solid transparent;
    transition: 0.2s ease-out;

    &:hover {
      border-color: #000e29;
    }

    @media (min-width: 992px) {
      margin-bottom: 20px;
      font-size: 36px;
      letter-spacing: 0.4px;
    }

    @media (max-width: 991px) {
      margin-bottom: 5px;
      font-size: 22px;
      letter-spacing: 0.2px;
    }
  }

  .copyright {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    a {
      color: var(--dark-100);
      text-decoration: none;
    }

    @media (max-width: 991px) {
      letter-spacing: 0.5px;
      line-height: 2;
    }

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .gray {
    opacity: 0.4;
  }

  .f-align {
    justify-content: space-between;
    align-items: center;
    // @media (max-width: 991px) {
    //   margin-bottom: 20px;
    // }
  }

  .f-right {
    display: flex;
    justify-content: flex-end;
    @media (min-width: 992px) {
      padding-left: 10px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  .f-top {
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  .f-bottom {
    margin-top: 20px;
    justify-content: space-between;

    @media (max-width: 767px) {
      margin-top: 15px;
      flex-direction: column;
    }
  }

  .f-socials-mob {
    @media (max-width: 991px) {
      max-width: 245px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .f-social-link {
    display: flex;
    align-items: center;
    color: #000e29;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;

    span {
      transition: 0.2s ease-out;
    }

    &:hover span {
      opacity: 1;
    }

    @media (min-width: 992px) and (max-width: 1240px) {
      span {
        font-size: 10px;
      }
    }

    @media (min-width: 768px) {
      span {
        position: relative;
        bottom: 1px;
      }
    }

    &_list {
      display: flex;

      &_mob {
        @media (min-width: 768px) {
          display: none;
        }

        @media (max-width: 767px) {
          display: flex;

          a {
            text-decoration: none;

            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
      }
    }

    &_item {
      &:not(:last-child) {
        margin-right: 21px;
      }

      .icon {
        margin-right: 3px;
        position: relative;
        bottom: 2px;
      }
    }
  }

  .dev {
    &_info {
      display: flex;

      @media (min-width: 768px) {
        justify-content: flex-end;
      }
    }

    &_text {
      display: block;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      white-space: nowrap;

      @media (min-width: 992px) and (max-width: 1240px) {
        font-size: 10px;
      }

      &:not(:last-child) {
        margin-right: 18px;
      }

      a {
        display: inline-block;
        color: #000e29;
        text-decoration: none;
        transition: 0.2s ease-out;
        border-bottom: 1px solid transparent;

        &:hover {
          opacity: 1;
          border-color: #000e29;
        }
      }
    }
  }

  .policy {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0.5px;

    &-link {
      color: var(--dark-40);
      transition: 0.2s ease-out;
      text-decoration: none;
      text-transform: uppercase;
      padding-right: 20px;

      &:hover {
        color: #000e29;
      }
    }

    &-card {
      display: flex;
      height: 16px;
      width: 32px;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  @media (min-width: 1200px) {
    &-lg {
      &-50 {
        width: 50%;
      }
    }
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    &-md {
      &-50 {
        width: 50%;
      }
      &-80 {
        width: 80%;
      }
      &-20 {
        width: 20%;
      }
    }
  }

  @media (max-width: 991px) and (min-width: 768px) {
    &-sm {
      &-100 {
        width: 100%;
      }
      &-50 {
        width: 50%;
      }
      &-80 {
        width: 80%;
      }
      &-20 {
        width: 20%;
      }
    }
  }

  @media (max-width: 767px) {
    &-xs {
      &-hidden {
        display: none;
      }
      &-100 {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  #dish-animation {
    transform: scale(0.5);
    height: 287px;
    margin-top: -48px;
  }

  .advantage-img-wrapper.day-animation-wrapper {
    transform: scale(0.431);
    height: 180px;
    margin-top: 21px;
  }
}

@media (min-width: 1200px) {
  #day-animation {
    height: 524px;
  }
}

.steps {
  &-wrapper {
    @media (min-width: 992px) {
      margin-bottom: 140px;
    }

    @media (max-width: 991px) {
      margin-bottom: 55px;
    }
  }

  &-inner {
    display: flex;

    @media (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 768px) {
      margin: 0 -10px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &.animated {
      .step-block {
        top: 0;
      }
    }
  }

  &-title {
    margin-bottom: 20px;
    font-family: "Aeonik Pro Regular", sans-serif;
    font-size: 36px;
    letter-spacing: 0.4px;

    @media (max-width: 991px) {
      margin-bottom: 24px;
      font-size: 22px;
    }
  }
}

.step {
  &-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--blue-light);
    border-radius: 10px;
    transition: 1s ease;

    &:nth-child(1) {
      top: 20px;
    }

    &:nth-child(2) {
      top: 40px;
    }

    &:nth-child(3) {
      top: 60px;
    }

    @media (min-width: 1200px) {
      margin: 0 10px;
      width: 33.333%;
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      margin: 0 10px 20px;
      width: 50%;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
      width: 100%;
    }

    .day-square {
      width: 54px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bg-main);
      border-radius: 8px;

      @media (max-width: 767px) {
        font-size: 9px;
      }

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.active {
        width: 70px;
        color: var(--bg-white);
        background: var(--blue-main);
        position: relative;

        &:after {
          content: url("data:image/svg+xml,%3Csvg width='33' height='12' viewBox='0 0 33 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.6794 0.874647C16.9883 0.356337 16.0381 0.356338 15.347 0.874648L0.963909 11.662H32.0625L17.6794 0.874647Z' fill='white'/%3E%3C/svg%3E%0A");
          position: absolute;
          top: calc(100% + 13px);
        }

        svg {
          margin-right: 4px;
        }
      }
    }

    .day-dish {
      margin-top: 6px;
      padding: 19px;
      width: 100%;
      background: var(--bg-white);
      border-radius: 8px;

      &-header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: var(--dark-100);

        @media (max-width: 767px) {
          font-size: 9px;
        }

        .day-name {
          font-size: 12px;

          @media (max-width: 991px) {
            font-size: 9px;
          }
        }
      }

      &-cell {
        margin-top: 10px;
        display: flex;
        align-items: center;

        img {
          margin-right: 12px;
          width: 54px;
          height: 54px;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
      }

      &-text {
        margin-bottom: 3px;
        display: inline-block;
        max-width: 240px;
        width: 100%;
        font-size: 14px;
        color: var(--dark-100);
        line-height: 23px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 767px) {
          font-size: 9px;
        }
      }

      &-price {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 18px;

        @media (max-width: 767px) {
          font-size: 9px;
        }

        svg {
          margin: 0 0 1px 2px;
          position: relative;

          @media (max-width: 767px) {
            width: 9px;
          }
        }
      }
    }
  }

  &-top {
    @media (min-width: 1381px) {
      padding: 46px 54px;
      height: 363px;
    }

    @media (max-width: 1380px) {
      padding: 20px 30px;
    }

    @media (max-width: 1380px) and (min-width: 1200px) {
      height: 325px;
    }

    @media (max-width: 767px) {
      margin: 0 auto;
      max-width: 320px;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    &-name,
    &-sum {
      font-size: 9px;
    }
  }

  &-bottom {
    padding: 0 20px 20px;
  }

  &-row {
    padding: 0 19px;
    width: 100%;
    height: 84px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: var(--dark-100);
    border-radius: 8px;
    background: var(--bg-white);

    @media (max-width: 1199px) {
      padding: 0 15px;
      height: 64px;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    .blue {
      color: var(--blue-main);
    }
  }

  &-label {
    margin-bottom: 10px;
    color: var(--dark-40);
    font-size: 12px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  &-text-main {
    font-family: "Aeonik Pro Regular", sans-serif;
    letter-spacing: 0.2px;
    color: var(--dark-100);

    @media (min-width: 1200px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: 1199px) {
      font-size: 16px;
    }

    @media (max-width: 991px) {
      line-height: 21px;
    }
  }
}

@media (max-width: 991px) {
  body.overflowed {
    overflow: hidden;
  }
}

body.overflowed .dropdown {
  visibility: visible;
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
