<template>
  <div class="dish-container" v-for="(dish, index) in dishes" :key="index">
    <div class="dish-card">
      <img class="dish-img status-1" :src="dish?.image" alt="dish" />
      <div class="dish-description m-text-food-title">
        <div class="main-description">
          <div class="icons-box">
            <template v-for="(allergen, index) in dish.allergens" :key="index">
              <div class="is-allergie">
                <InfoMessage :class="{ 'status-1': allergen?.is_show }" bottom="38px" left="-21px" arrowDir="bl"><span>{{ allergen.text }}</span></InfoMessage>
                <img class="svg" :src="allergen?.icon" />
              </div>
            </template>
          </div>
          <span class="title">
            <span v-if="!animation" class="status-1">{{ dish.text }}</span>
          </span>
        </div>
        <span class="price">{{ dish.price }} ₴</span>
      </div>
    </div>
  </div>
</template>

<script>
import InfoMessage from "@/components/parts/info-message";
export default {
  components: {
    InfoMessage
  },
  props: {
    dishes: {
      type: Array
    }
  },
  data() {
    return {
      showCheckbox: false,
      animation: false
    };
  }
};
</script>

<style lang="scss" scoped>
.dish-container {
  padding-top: 67px;

  &:first-child {
    margin-top: 133px;
    margin-left: -10px;
  }
}
.is-allergie .info-meassage {
  visibility: visible;
  opacity: 1;
}
.dish-card {
  margin: 0 10px;
  position: relative;
  width: 335px;
  height: 401px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--white-100);
  border-radius: 10px;
}
.dish-img {
  position: absolute;
  top: -70px;
  left: calc(50% - 8px);
  transform: translateX(-50%);
  max-width: 334px;
  max-height: 330px;
  transition: 0.15s;
}
.dish-description {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 4px;
}
.main-description {
  display: flex;
  flex-direction: column;
}
.icons-box {
  margin-bottom: 4px;
  display: flex;
}
.is-allergie {
  margin-right: 5px;
  position: relative;
}
.is-spicy {
  display: none;
}

.price {
  margin-left: 40px;
  white-space: nowrap;
}
.dish-btn {
  user-select: none;
  transition: 0.2s;
  color: var(--dark-40);
  width: 100%;
  height: 48px;
  background: var(--bg-main);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  &:hover {
    color: var(--white-100);
    background: var(--blue-main);
  }
}
.dish-check-container {
  display: flex;
  label {
    width: 100%;
    margin: 0 2px;
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"]:checked + .dish-btn {
    color: var(--white-100);
    background: var(--blue-main);
    .svg {
      display: flex;
    }
  }
}

.title {
  position: relative;
}

.title span {
  text-align: left;
}

.dish-img.status-1 {
  opacity: 1;
}

.animated .dish-img.status-1 {
  animation: pulse 1s;
  opacity: 0;
}

.dish-img.status-2 {
  opacity: 0;
}

.animated .dish-img.status-2 {
  animation: pulse 1s;
  opacity: 1;
}

.info-meassage {
  display: none;
}

.info-meassage.status-1 {
  display: block;
  transition: 0.5s ease;
}

.info-meassage.status-2 {
  transition: 1s ease;
}

.info-meassage.status-2 {
  opacity: 0;
}

.animated .info-meassage.status-2 {
  opacity: 1;
}

.animated .info-meassage.status-1 {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease;
  position: absolute;
  width: 100%;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-2-enter-active,
.fade-2-leave-active {
  transition: opacity 100ms ease;
}

.fade-2-enter-from,
.fade-2-leave-to {
  opacity: 0;
}

.svg {
  height: 16px;
  width: 16px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
