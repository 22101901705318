<template>
  <div>
    <div class="faq-block" :class="accordionClasses(item)" v-for="(item, index) in faq" :key="index">
      <div class="faq-top" @click="toggleAccordion(item, index)">
        <p class="faq-question">{{ item.question }}</p>
        <svg class="status-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H12M12 12H5M12 12V5M12 12V19" stroke="#000E29" stroke-opacity="0.4" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <svg class="status-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 19L5 5M19 5L5 19" stroke="#3CAFE9" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div :id="`faq-bottom-${index}`" class="faq-bottom">
        <p class="faq-content">{{ item.answer }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faq: {
      type: Array
    }
  },
  methods: {
    toggleAccordion(item, index) {
      item.show = !item.show;

      const elem = document.getElementById(`faq-bottom-${index}`);
      const child = elem.querySelector(".faq-content");

      if (item.show) {
        elem.style.position = "absolute";
        elem.style.display = "block";

        const height = child.offsetHeight;

        elem.style.position = null;
        elem.style.visibility = null;

        elem.style.height = height + 6 + "px";
      } else {
        elem.style.height = null;
      }
    },
    accordionClasses(item) {
      return {
        "is-closed": !item.show,
        "is-primary": item.show
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.faq {
  &-block {
    margin-bottom: 8px;
    background: var(--bg-white);
    border-radius: 10px;
  }

  &-top {
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--dark-100);
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 991px) {
      padding: 23px 20px;
      align-items: flex-start;
    }
  }

  &-question {
    width: calc(100% - 40px);
    letter-spacing: 0.48px;
    line-height: 24px;

    @media (max-width: 991px) {
      line-height: 24px;
    }
  }

  &-content {
    color: var(--dark-40);
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;

    @media (max-width: 767px) {
      padding-bottom: 8px;
    }
  }

  &-bottom {
    padding: 0 65px 0 20px;
    height: 0;
    transition: height 0.5s ease-in-out;
    overflow: hidden;
  }
}
.status-1,
.status-2 {
  position: absolute;
  right: 20px;
  transition: 0.5s ease-in-out;
  opacity: 1;
}
.faq-bottom {
  position: relative;
  bottom: 10px;
}
.is-closed .faq-bottom {
  display: none;
}
.is-closed .status-2 {
  opacity: 0;
}
.is-primary .faq-top {
  color: var(--blue-main);
}
.is-primary .status-1 {
  opacity: 0;
  transform: rotate(90deg);
}
.is-primary .status-2 {
  transform: rotate(180deg);
}
</style>
