<template>
  <div class="dish-day" :class="{ collapsed: dish?.is_order && index != 0, opened: index == 0 }">
    <div class="day-header">
      <div class="day s-text-caption">{{ dish.title }}</div>
      <div v-if="dish.order_price" class="price s-text-caption">{{ dish.order_price }} ₴</div>
      <div v-else class="add-dish s-text-caption">{{ dish.empty_text }}</div>
    </div>
    <div v-if="dish.is_already || dish.is_confirm" class="dish-status">
      <div v-if="dish.is_already" class="already-preparing">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.33594 8.33333L7.66927 11.6667L14.3359 5" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.66667 8.33333L11 5M1 8.33333L4.33333 11.6667L1 8.33333Z" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="s-text-caption">{{ dish.already_text }}</span>
      </div>
      <div v-if="dish.is_confirm" class="order-confirmation">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 5V8.125L10 10" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="s-text-caption">{{ dish.confirm_text }}</span>
      </div>
    </div>
    <div v-if="dish.dishes != ''" class="day-bottom">
      <div class="day-bottom-inner">
        <div class="dish-cell" v-for="(item, index) in dish.dishes" :key="index">
          <img :src="item?.image" alt="dish-min" />
          <div class="cell-text m-text-des">
            <span title="Борщ со сметаной" class="cell-title">{{ item?.text }} </span>
            <div class="count-price s-text-caption">
              {{ item?.price }}
              <div class="remove-dish-btn">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.30078 12.5212L12.0754 4.74658M4.30078 4.74658L12.0754 12.5212" stroke="#000E29" stroke-opacity="0.4" stroke-width="1.45775" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    dish: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.dish-day {
  min-height: 0px;
  background: var(--white-100);
  padding: 20px;
  border-radius: 10px;
  width: 355px;

  margin-bottom: 20px;
}
.day-header {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.add-dish,
.price,
.day {
  text-transform: uppercase;
}
.add-dish {
  color: var(--dark-40);
}
.order-confirmation,
.already-preparing {
  cursor: default;
  display: flex;
  align-items: center;
  color: var(--blue-main);
  svg {
    margin-top: 0;
    margin-right: 4px;
  }
}
.dish-cell {
  transition: all 300ms linear;
  display: flex;
  height: 60px;

  &:not(:first-child) {
    margin-top: 12px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  img {
    height: 100%;
    max-width: 60px;
    border-radius: 100%;
    filter: drop-shadow(-3px 5px 5px rgba(0, 0, 0, 0.2));
  }
}
.collapsed .dish-cell img {
  opacity: 0;
  transform: scale(0);
  transition: 350ms ease;
}
.collapsed .dish-cell.animated img {
  transform: scale(1);
  opacity: 1;
}
.collapsed .dish-cell .cell-text {
  opacity: 0;
  transition: opacity 350ms ease;
}
.collapsed .dish-cell.animated .cell-text {
  opacity: 1;
}
.day-bottom {
  margin-top: 12px;
  height: 0;
  // overflow: hidden;
  transition: 500ms ease-in;
}
.opened .day-bottom {
  height: auto;
}
.cell-title {
  max-width: 260px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cell-text {
  width: calc(100% - 56px);
  margin-left: 12px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}
.count-price {
  display: flex;
  align-items: center;
  color: var(--dark-40);
  line-height: 1;
  position: relative;
  bottom: 6px;
  .remove-dish-btn {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
    margin-left: 2px;
  }
}
.surcharge {
  user-select: none;
  color: var(--blue-main);
  margin-left: auto;
}

#day-animation .animated .day-bottom {
  height: initial;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
</style>
